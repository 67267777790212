<!--
 * @Description: 数据统计
 * @Autor: winzhao
 * @Date: 2021-10-21 15:10:57
 * @LastEditors: winzhao
 * @LastEditTime: 2021-10-22 09:30:28
-->
<template>
  <div>
    <div class="data-wrapper">
      <div class="elder-echarts">
        <div class="elder-echarts__header data-title">
          <i class="el-icon-pie-chart"></i>
          <div class="title">人员分布统计</div>
        </div>
        <div id="elderEcharts" class="elder-echarts__content"></div>
      </div>
      <div class="company-wrapper">
        <div class="company-wrapper__header data-title">
          <i class="el-icon-document"></i>
          <div class="title">企业信息统计</div>
        </div>
        <div class="company-wrapper__content">
          <company-card v-for="item in companyData" :key="item.key" :company-data="item" />
        </div>
      </div>
    </div>
    <div class="elder-moredata">
      <div class="elder-moredata__left">
        <percentage-card v-for="(item, index) in moreDataLeft" :key="item.key" :card-data="item" :card-index="index + 1" />
      </div>
      <div class="elder-moredata__right">
        <percentage-card v-for="(item, index) in moreDataRight" :key="item.key" :card-data="item" :card-index="index + 7" :progres-color="'#5cd314'" />
      </div>
    </div>
  </div>
</template>

<script>
import PercentageCard from './PercentageCard'
import CompanyCard from './CompanyCard'
import { mapActions } from 'vuex'

export default {
  components: {
    PercentageCard,
    CompanyCard,
  },
  data() {
    return {
      ageList: [
        {
          key: 'personAge0Cnt',
          name: '60岁以下',
          value: 0,
        },
        {
          key: 'personAge60Cnt',
          name: '60-69岁',
          value: 0,
        },
        {
          key: 'personAge70Cnt',
          name: '70-79岁',
          value: 0,
        },
        {
          key: 'personAge80Cnt',
          name: '80-89岁',
          value: 0,
        },
        {
          key: 'personAge90Cnt',
          name: '90-99岁',
          value: 0,
        },
        {
          key: 'personAge100Cnt',
          name: '100岁以上',
          value: 0,
        },
      ],
      elderTotal: 0,
      elderData: {},
      moreDataLeft: [
        {
          key: 'malePersonCnt',
          name: '男性',
          value: 0,
          percentage: 0,
        },
        {
          key: 'femalePersonCnt',
          name: '女性',
          value: 0,
          percentage: 0,
        },
        {
          key: 'homeServicePersonCnt',
          name: '居家养老服务补贴 ',
          value: 0,
          percentage: 0,
        },
        {
          key: 'homeAllowancePersonCnt',
          name: '护理-居家养老补贴',
          value: 0,
          percentage: 0,
        },
        {
          key: 'orgAllowancePersonCnt',
          name: '护理-机构养老补贴',
          value: 0,
          percentage: 0,
        },
        {
          key: 'noSelfCarePerson80Cnt',
          name: '80岁以上的失能老人',
          value: 0,
          percentage: 0,
        },
      ],
      moreDataRight: [
        {
          key: 'socialPersonCnt',
          name: '社会化老人',
          value: 0,
          percentage: 0,
        },
        {
          key: 'aloneLivingPersonCnt;',
          name: '独居老人',
          value: 0,
          percentage: 0,
        },
        {
          key: 'emptyNesterPersonCnt',
          name: '空巢老人 ',
          value: 0,
          percentage: 0,
        },
        {
          key: 'disablePersonCnt',
          name: '残疾人',
          value: 0,
          percentage: 0,
        },
        {
          key: 'lowIncomePersonCnt',
          name: '低保老人',
          value: 0,
          percentage: 0,
        },
        {
          key: 'deadPersonCnt',
          name: '死亡老人',
          value: 0,
          percentage: 0,
        },
      ],
      companyData: [
        {
          key: 'enterpriseCnt',
          name: '管理企业数',
          value: 0,
          unit: '家',
          icon: 'el-icon-s-home',
          color: '#64c2f8',
        },
        {
          key: 'serviceWorkPersonCnt',
          name: '上门服务人员',
          value: 0,
          unit: '人',
          icon: 'el-icon-s-custom',
          color: '#f04f58',
        },
        {
          key: 'serviceTimeCnt',
          name: '服务次数',
          value: 0,
          unit: '次',
          icon: 'el-icon-s-data',
          color: '#5cd314',
        },
      ],
    }
  },
  computed: {
    legendData() {
      return this.ageList.map((item) => item.name)
    },
  },
  mounted() {
    this.getHomeCenterData()
  },
  methods: {
    ...mapActions(['orgDataCenterQueryData']),
    getHomeCenterData() {
      this.loading = true
      this.orgDataCenterQueryData({}).then((res) => {
        this.loading = false
        this.initComanyData(res)
        this.setNewElderData(res)
      })
    },
    initComanyData(res) {
      let data = res.data
      let res1 = {
        serviceTimeCnt: data.serviceTimeCnt,
        enterpriseCnt: data.enterpriseCnt,
        serviceWorkPersonCnt: data.serviceWorkPersonCnt,
      }
      this.companyData.forEach((item) => {
        item.value = res1[item.key] ? res1[item.key] : 0
      })
    },
    setNewElderData(res) {
      let data = res.data
      this.elderTotal = parseInt(data.personAge.personCnt, 10)
      if (data.personAge) {
        this.ageList.forEach((item) => {
          item.value = data.personAge[item.key] ? data.personAge[item.key] : 0
        })
      }
      this.moreDataLeft.forEach((item) => {
        item.value = data[item.key] ? data[item.key] : 0
        item.percentage = this.elderTotal ? this._.divide(item.value, this.elderTotal) : 0
        item.percentage = this._.round(this._.multiply(item.percentage, 100), 2)
      })
      this.moreDataRight.forEach((item) => {
          item.value = data[item.key] ? data[item.key] : 0
          item.percentage = this.elderTotal ? this._.divide(item.value, this.elderTotal) : 0
          item.percentage = this._.ceil(this._.multiply(item.percentage, 100), 2)
        })
      this.initElderEcharts()
    },
    initElderEcharts() {
      let myChart = this.$echarts.init(document.getElementById('elderEcharts'))
      // 绘制图表
      myChart.setOption({
        // 提示框
        tooltip: {
          trigger: 'item',
        },
        // 图例属性
        legend: {
          show: true,
          left: 'center',
          top: 'bottom',
          itemGap: 40,
          textStyle: {
            fontSize: 18,
            color: '#8e9aa9',
          },
          data: this.legendData,
        },
        // 圆心区域
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: `系统管理总人数\n\n${this.elderTotal}`,
            textAlign: 'center',
            fill: '#ff0000',
            fontSize: 24,
          },
        },
        // 系列列表
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              show: true,
              fontSize: 22,
              formatter: '{b}:\n{c} 人({d}%)',
            },
            labelLine: {
              length1: 10,
              length2: 20,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 24,
                fontWeight: 'bold',
              },
            },
            data: this.ageList,
            color: ['#64C2F8', '#F04F58', '#FFDB48', '#5CD314', '#7D75C8'],
          },
        ],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.data-wrapper {
  width: 100%;
  height: 650px;
  border-bottom: 1px solid #edeff5;
  display: flex;
  flex-direction: row;
  .elder-echarts {
    width: 60%;
    min-width: 800px;
    height: 650px;
    border-right: 1px solid #edeff5;
    &__content {
      height: 500px;
    }
  }
  .company-wrapper {
    width: 40%;
    min-width: 400px;
    height: 650px;
    &__content {
      height: 400px;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.elder-moredata {
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &__left,
  &__right {
    flex: 1;
    padding: 0 30px;
  }
}
.data-title {
  padding-left: 20px;
  height: 100px;
  color: #ed535c;
  i {
    font-size: 40px;
    line-height: 100px;
    float: left;
  }
  .title {
    font-size: 24px;
    height: 100px;
    line-height: 100px;
    padding-left: 10px;
    font-weight: bold;
    float: left;
  }
}
</style>
